import React from 'react';

const ContentSaveMoney = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9767 28.2298H28.1214C27.3758 28.2298 26.6609 27.9301 26.1337 27.3967C25.6065 26.8633 25.3103 26.1399 25.3103 25.3855C25.3103 25.197 25.3844 25.016 25.5162 24.8826C25.648 24.7493 25.8267 24.6745 26.0131 24.6745C26.1994 24.6745 26.3783 24.7493 26.5101 24.8826C26.6419 25.016 26.7158 25.197 26.7158 25.3855C26.7158 25.7929 26.8759 26.1835 27.1605 26.4716C27.4452 26.7596 27.8313 26.9214 28.2339 26.9214H30.0891C30.4917 26.9214 30.8778 26.7596 31.1625 26.4716C31.4472 26.1835 31.607 25.7929 31.607 25.3855C31.607 24.9782 31.4472 24.5875 31.1625 24.2995C30.8778 24.0115 30.4917 23.8495 30.0891 23.8495H28.2339C27.4585 23.8495 26.7149 23.538 26.1666 22.9833C25.6184 22.4285 25.3103 21.6761 25.3103 20.8916C25.3103 20.107 25.6184 19.3546 26.1666 18.7999C26.7149 18.2451 27.4585 17.9336 28.2339 17.9336H30.0891C30.8347 17.9336 31.5496 18.2331 32.0768 18.7665C32.604 19.2999 32.9002 20.0235 32.9002 20.7779C32.9002 20.9664 32.8261 21.1472 32.6943 21.2806C32.5625 21.4139 32.3838 21.4889 32.1974 21.4889C32.011 21.4889 31.8323 21.4139 31.7005 21.2806C31.5687 21.1472 31.4946 20.9664 31.4946 20.7779C31.4946 20.3705 31.3348 19.9799 31.0501 19.6918C30.7654 19.4038 30.3793 19.2418 29.9767 19.2418H28.1214C27.7188 19.2418 27.3327 19.4038 27.048 19.6918C26.7633 19.9799 26.6034 20.3705 26.6034 20.7779C26.6034 21.1852 26.7633 21.5759 27.048 21.8639C27.3327 22.1519 27.7188 22.3137 28.1214 22.3137H29.9767C30.7521 22.3137 31.4956 22.6254 32.0438 23.1801C32.5921 23.7349 32.9002 24.4871 32.9002 25.2717C32.9002 26.0562 32.5921 26.8086 32.0438 27.3634C31.4956 27.9181 30.7521 28.2298 29.9767 28.2298Z"
      fill="#FCFCFD"
    />
    <path
      d="M29.0494 19.2406C28.8631 19.2406 28.6844 19.1658 28.5526 19.0324C28.4208 18.8991 28.3467 18.7181 28.3467 18.5295V16.4533C28.3467 16.2647 28.4208 16.0839 28.5526 15.9505C28.6844 15.8172 28.8631 15.7422 29.0494 15.7422C29.2358 15.7422 29.4145 15.8172 29.5463 15.9505C29.6781 16.0839 29.7522 16.2647 29.7522 16.4533V18.5295C29.7522 18.7181 29.6781 18.8991 29.5463 19.0324C29.4145 19.1658 29.2358 19.2406 29.0494 19.2406Z"
      fill="#FCFCFD"
    />
    <path
      d="M29.0494 30.307C28.8631 30.307 28.6844 30.232 28.5526 30.0987C28.4208 29.9653 28.3467 29.7845 28.3467 29.5959V27.5197C28.3467 27.3311 28.4208 27.1501 28.5526 27.0167C28.6844 26.8834 28.8631 26.8086 29.0494 26.8086C29.2358 26.8086 29.4145 26.8834 29.5463 27.0167C29.6781 27.1501 29.7522 27.3311 29.7522 27.5197V29.5959C29.7522 29.7845 29.6781 29.9653 29.5463 30.0987C29.4145 30.232 29.2358 30.307 29.0494 30.307Z"
      fill="#FCFCFD"
    />
    <path
      d="M33.2936 7.70181H24.8605C24.7201 7.69997 24.5835 7.65567 24.4683 7.57456C24.353 7.49345 24.2643 7.37932 24.2138 7.24681L21.8808 1.75741C21.7907 1.52851 21.7678 1.27815 21.8149 1.03645C21.862 0.794741 21.977 0.572001 22.1462 0.394821C22.3154 0.217642 22.5315 0.0935155 22.7687 0.0375531C23.0058 -0.0184093 23.2539 -0.00394337 23.4831 0.079217L25.507 0.875693C26.4664 0.384674 27.5241 0.121948 28.5992 0.107687H29.6112C30.6866 0.118903 31.7449 0.381796 32.7032 0.875693L34.7273 0.079217C34.9579 -0.00693995 35.2083 -0.0232195 35.4479 0.0323451C35.6875 0.0879097 35.906 0.212869 36.0765 0.392044C36.2506 0.56679 36.368 0.791045 36.4132 1.03488C36.4583 1.27872 36.4293 1.53073 36.3296 1.75741L33.9964 7.24681C33.9427 7.38867 33.8454 7.50933 33.7189 7.59123C33.5925 7.67312 33.4434 7.71187 33.2936 7.70181ZM25.2541 6.27968H32.8438L34.8397 1.52982L32.7876 2.35459C32.6577 2.39803 32.5184 2.40512 32.3848 2.37507C32.2512 2.34503 32.1282 2.27899 32.0286 2.18394C31.2662 1.74675 30.4031 1.52114 29.5267 1.52982H28.5711C27.6943 1.51702 26.8301 1.74291 26.0692 2.18394C25.9714 2.28176 25.8483 2.34975 25.714 2.37994C25.5798 2.41012 25.4397 2.40128 25.3102 2.35459L23.2582 1.52982L25.2541 6.27968Z"
      fill="#FFF500"
    />
    <path
      d="M33.2934 10.2878H24.8602C24.6738 10.2878 24.495 10.2129 24.3632 10.0795C24.2314 9.94618 24.1575 9.76537 24.1575 9.57678V6.98841C24.1575 6.79982 24.2314 6.61901 24.3632 6.48566C24.495 6.35231 24.6738 6.27734 24.8602 6.27734C25.0466 6.27734 25.2253 6.35231 25.3571 6.48566C25.4889 6.61901 25.563 6.79982 25.563 6.98841V8.86572H32.6467V6.98841C32.6467 6.79982 32.7208 6.61901 32.8526 6.48566C32.9844 6.35231 33.1631 6.27734 33.3495 6.27734C33.5359 6.27734 33.7147 6.35231 33.8465 6.48566C33.9783 6.61901 34.0522 6.79982 34.0522 6.98841V9.57678C34.0526 9.67515 34.0329 9.7725 33.9941 9.8627C33.9553 9.9529 33.8983 10.0339 33.827 10.1007C33.7556 10.1675 33.6714 10.2187 33.5796 10.2509C33.4877 10.2831 33.3903 10.2957 33.2934 10.2878Z"
      fill="#FFF500"
    />
    <path
      d="M16.9609 28.2316C16.8089 28.2316 16.6608 28.1817 16.5392 28.0894C16.4175 27.9971 16.3289 27.8674 16.2863 27.7197C15.8736 26.4036 15.6557 25.033 15.6396 23.6524C15.5797 20.6099 16.3749 17.6127 17.9325 15.0096C19.4901 12.4064 21.7461 10.3041 24.4382 8.94745C24.6077 8.86217 24.8027 8.84414 24.9847 8.89676C25.1667 8.94938 25.3228 9.06896 25.4222 9.23198C25.4667 9.3141 25.4948 9.40429 25.5047 9.49741C25.5147 9.59054 25.5063 9.68462 25.4802 9.77448C25.4541 9.86434 25.4107 9.94816 25.3525 10.021C25.2943 10.0938 25.2224 10.1543 25.141 10.1989C22.6933 11.4335 20.6371 13.338 19.2059 15.6961C17.7748 18.0543 17.0261 20.7714 17.0452 23.5385C17.0467 24.7927 17.2363 26.0394 17.6074 27.236C17.6378 27.3237 17.6502 27.4168 17.6438 27.5094C17.6374 27.6021 17.6124 27.6925 17.5703 27.775C17.5282 27.8576 17.4699 27.9305 17.3989 27.9894C17.328 28.0484 17.2459 28.092 17.1577 28.1177L16.9609 28.2316Z"
      fill="#FFF500"
    />
    <path
      d="M35.6828 35.2927C35.5324 35.2893 35.3872 35.2372 35.2683 35.1441C35.1494 35.0509 35.0631 34.9216 35.0222 34.7752C34.9813 34.6287 34.9878 34.4729 35.0409 34.3306C35.0941 34.1882 35.191 34.0667 35.3173 33.9842C37.0538 32.8848 38.4825 31.3532 39.4668 29.5356C40.4511 27.718 40.9581 25.6753 40.9394 23.6028C40.9853 20.8301 40.2478 18.1018 38.8137 15.7387C37.3795 13.3756 35.3083 11.476 32.8436 10.2632C32.6841 10.1682 32.5681 10.0134 32.5207 9.83233C32.4733 9.65127 32.4985 9.4585 32.5907 9.29608C32.6849 9.13517 32.836 9.01642 33.0133 8.96364C33.1906 8.91086 33.381 8.92805 33.5464 9.01173C36.2385 10.3684 38.4946 12.4707 40.0522 15.0738C41.6098 17.677 42.4049 20.674 42.345 23.7165C42.3202 26.0077 41.7187 28.2546 40.5973 30.2452C39.4759 32.2359 37.8716 33.9045 35.9359 35.0935C35.8703 35.1812 35.783 35.2499 35.6828 35.2927Z"
      fill="#FFF500"
    />
    <path
      d="M28.8829 46.1541C28.6591 46.1852 28.4321 46.1852 28.2083 46.1541C22.4712 45.4729 16.6963 45.1689 10.9203 45.2439C10.7339 45.2439 10.5552 45.1689 10.4234 45.0356C10.2916 44.9022 10.2175 44.7214 10.2175 44.5328C10.2245 44.3465 10.3008 44.1697 10.4311 44.0379C10.5615 43.906 10.7361 43.8288 10.9203 43.8217C16.8378 43.7611 22.7531 44.0842 28.6299 44.7889C29.9792 45.2155 37.0631 42.5134 46.2552 38.1048C46.3621 38.041 46.4422 37.9399 46.4801 37.8204C46.5239 37.7109 46.5239 37.5886 46.4801 37.4791C46 36.8031 45.3114 36.3077 44.5226 36.0709C43.7339 35.834 42.8899 35.8692 42.123 36.1707L33.6899 39.5554C32.7773 39.8613 31.7915 39.8613 30.8788 39.5554L26.3529 38.0763C26.2637 38.0474 26.1811 38.0008 26.11 37.9391C26.0388 37.8774 25.9805 37.8019 25.9386 37.7171C25.8967 37.6323 25.8719 37.5399 25.8658 37.4452C25.8597 37.3506 25.8725 37.2559 25.9032 37.1663C25.9671 36.9883 26.096 36.8419 26.2634 36.7573C26.4307 36.6726 26.6238 36.656 26.8028 36.7111L31.3004 38.1902C31.918 38.4161 32.5943 38.4161 33.2119 38.1902L41.645 34.834C42.7008 34.4048 43.8688 34.3481 44.9603 34.6732C46.0518 34.9983 47.0032 35.6863 47.6607 36.6259C47.8155 36.8456 47.921 37.0969 47.9695 37.3623C48.0181 37.6276 48.0085 37.9004 47.9417 38.1617C47.8751 38.422 47.7524 38.6641 47.5823 38.8707C47.4122 39.0772 47.199 39.243 46.958 39.3562C40.1833 42.3711 32.0313 46.1541 28.8829 46.1541Z"
      fill="#FCFCFD"
    />
    <path
      d="M31.8341 37.9648H19.2688C19.0824 37.9648 18.9035 37.8898 18.7718 37.7565C18.64 37.6231 18.566 37.4423 18.566 37.2538C18.566 37.0652 18.64 36.8844 18.7718 36.751C18.9035 36.6177 19.0824 36.5427 19.2688 36.5427H31.8341C31.8362 35.7659 31.5474 35.0173 31.0258 34.4473C30.5042 33.8774 29.7886 33.5284 29.0231 33.4708C27.3121 33.4473 25.6038 33.3239 23.9069 33.1011C22.7096 32.8985 21.5333 32.5845 20.3931 32.1626C18.5987 31.4518 16.6971 31.0572 14.771 30.9963C11.4821 30.9963 7.63097 35.405 7.57475 35.4619C7.44564 35.5747 7.28038 35.6361 7.10978 35.6348C6.93918 35.6334 6.77489 35.5693 6.64757 35.4544C6.52024 35.3395 6.4386 35.1817 6.41783 35.0103C6.39706 34.839 6.43857 34.6658 6.53467 34.5232C6.70333 34.3241 10.8637 29.5742 14.771 29.5742C16.8106 29.6465 18.8242 30.0597 20.7304 30.7972C21.7883 31.1985 22.8797 31.5032 23.9913 31.7074C25.6326 31.9179 27.2845 32.0319 28.9388 32.0487C30.0922 32.0857 31.1861 32.5754 31.9889 33.4142C32.7916 34.2531 33.2402 35.3751 33.2396 36.5427C33.2396 36.9199 33.0916 37.2817 32.828 37.5484C32.5644 37.8151 32.2069 37.9648 31.8341 37.9648Z"
      fill="#FCFCFD"
    />
    <path
      d="M6.31051 48.998C6.17335 48.9984 6.03919 48.9583 5.9243 48.8825C5.80941 48.8067 5.71889 48.6984 5.66402 48.5713L0.0419275 35.061C-0.0139758 34.8853 -0.0139758 34.6963 0.0419275 34.5206C0.106835 34.3442 0.238268 34.2011 0.407378 34.1225L5.74844 31.847C5.83267 31.8052 5.92457 31.7817 6.01832 31.7779C6.11208 31.7741 6.20554 31.79 6.29284 31.8248C6.38014 31.8596 6.45934 31.9125 6.52532 31.98C6.59129 32.0475 6.64255 32.128 6.67596 32.2168L12.2981 45.727C12.3404 45.8157 12.3624 45.913 12.3624 46.0115C12.3624 46.11 12.3404 46.2072 12.2981 46.2959C12.2688 46.3815 12.2209 46.4594 12.1575 46.5235C12.0942 46.5875 12.0172 46.6361 11.9326 46.6656L6.59172 48.941C6.50666 48.9905 6.40784 49.0105 6.31051 48.998ZM1.58797 35.1464L6.7041 47.3767L10.7239 45.6418L5.63589 33.4399L1.58797 35.1464Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default ContentSaveMoney;
